import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'
import { H2, H3 } from '@emico/ui'

import theme from '../theme'

const titleStyling = css`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
`

const StyledH2 = styled(H2, {
  shouldForwardProp: (prop) => !['type'].includes(prop.toString()),
})<{ hasLargeTitle: boolean }>`
  ${titleStyling};

  @media ${minWidth('md')} {
    font-size: ${({ hasLargeTitle }) =>
      hasLargeTitle && theme.fontSizes['2xl']};
  }
`

const StyledH3 = styled(H3, {
  shouldForwardProp: (prop) => !['type'].includes(prop.toString()),
})<{ hasLargeTitle: boolean }>`
  ${titleStyling};
  text-transform: uppercase;

  @media ${minWidth('md')} {
    font-size: ${({ hasLargeTitle }) =>
      hasLargeTitle && theme.fontSizes['2xl']};
  }
`

interface Props {
  title: ReactNode | string
  hasLargeTitle?: boolean
  titleElement?: 'h2' | 'h3'
}

const SectionTitle = ({
  title,
  hasLargeTitle = false,
  titleElement = 'h2',
  ...other
}: Props) => {
  switch (titleElement) {
    case 'h3':
      return (
        <StyledH3 hasLargeTitle={hasLargeTitle} {...other}>
          {title}
        </StyledH3>
      )
    default:
      return (
        <StyledH2 hasLargeTitle={hasLargeTitle} {...other}>
          {title}
        </StyledH2>
      )
  }
}

export default SectionTitle
